







































import Vue from "vue";
export default Vue.extend({
	data() {
		return {
			imageFile: {} as File,
			imageFileBase64: "" as string,
			isOnDragOver: false,

			name: "",
			school: "",
			introduction: "",

			isDeleteAble: false,
			deleteClubName: ""
		};
	},
	created() {
		this.name = this.getClub.name;
		this.school = this.getClub.school;
		this.introduction = this.getClub.introduction;
	},
	methods: {
		onImageChange(e: any) {
			this.imageFile = e.target.files[0];
		},
		onImageDrop(e: DragEvent) {
			e.preventDefault();

			let file = e.dataTransfer!.files[0];
			if (file) {
				this.imageFile = file;
			}
			this.isOnDragOver = false;
		},
		onImageDropOver(e: DragEvent) {
			this.isOnDragOver = true;
			e.preventDefault();
		},
		encodeBase64ImageFile(image: File): Promise<string> {
			return new Promise<string>((resolve, reject) => {
				if (!image) return resolve("");
				let reader = new FileReader();
				reader.readAsDataURL(image);
				reader.onload = (event: any) => {
					resolve(event.target.result);
				};
				reader.onerror = error => {
					reject(error);
				};
			});
		},
		save() {
			if (this.name.trim().length > 0 && this.school.trim().length > 0 && this.introduction.trim().length > 0) {
				this.$store.commit("pushLoading", {
					name: "CLUB_MODIFICATION",
					message: "동아리 사이트 수정 중"
				});
				this.$store
					.dispatch("CLUB_MODIFICATION", {
						name: this.name,
						school: this.school,
						introduction: this.introduction
					})
					.then(club => {
						this.$store.commit("clearLoading", "CLUB_MODIFICATION");
						this.$store.commit("showNotice", `동아리 정보 수정 성공`);
						if (this.imageFileBase64) {
							this.$store.commit("pushLoading", {
								name: "SET_CLUB_IMAGE",
								message: "동아리 사이트 수정 중"
							});
							this.$store
								.dispatch("SET_CLUB_IMAGE", {
									img: this.imageFileBase64
								})
								.then(data => {
									this.$store.commit("clearLoading", "SET_CLUB_IMAGE");
									this.$router.replace(`/${this.name}/community/editclub`);
								})
								.catch(err => console.dir(err));
						} else {
							this.$router.replace(`/${this.name}/community/editclub`);
						}
					})
					.catch(err => console.dir(err));
			}
		},
		deleteClub() {
			if (this.deleteClubName == this.name) {
				this.$store.commit("pushLoading", {
					name: "CLUB_DELETE",
					message: "동아리 사이트 수정 중"
				});
				this.$store
					.dispatch("CLUB_DELETE", {
						name: this.name,
						school: this.school,
						introduction: this.introduction
					})
					.then(club => {
						this.$store.commit("clearLoading", "CLUB_DELETE");
						this.$store.commit("setClub", {});
						this.$router.replace("/");
					})
					.catch(err => console.dir(err));
			}
		}
	},
	watch: {
		imageFile() {
			if (this.imageFile) {
				this.$store.commit("pushLoading", {
					name: "encodeBase64ImageFile",
					message: "동아리 사이트 수정 중"
				});
				this.encodeBase64ImageFile(this.imageFile).then((imagebase64: string) => {
					this.imageFileBase64 = imagebase64;
					this.$store.commit("clearLoading", "encodeBase64ImageFile");
				});
			}
		}
	},
	computed: {
		getClubImage(): string {
			if (this.imageFileBase64) return this.imageFileBase64;
			else if (this.$store.state.club.imgPath) return this.$store.state.mainPath + this.$store.state.club.imgPath;
			else return "https://pbs.twimg.com/profile_images/770139154898382848/ndFg-IDH_400x400.jpg";
		},
		getClub() {
			return this.$store.state.club;
		},
		isOwner(): boolean {
			return this.getClub.owner == this.$store.state.userInformation._id;
		}
	}
});
